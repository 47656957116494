<template>
  <div class="nav-bar-layout-wrapper">
    <jhi-navbar></jhi-navbar>
    <router-view></router-view>
    <jhi-footer></jhi-footer>
  </div>
</template>

<script lang="ts" setup>
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';


</script>

<style scoped>
.nav-bar-layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
</style>

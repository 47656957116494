<template>
  <div id="app">
    <ribbon></ribbon>
    <router-view></router-view>
  </div>
</template>

<script lang="ts" setup>
import Ribbon from '@/core/ribbon/ribbon.vue';
</script>
<style scoped>
.content-boxed {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding: 20px;
}

html,
body,
#app {
  min-height: 100vh;
}
</style>

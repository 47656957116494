@import 'vendor';

.boxed-layout, .content-wrapper {
  padding: 40px 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

$efm-blue: rgb(0, 65, 121);
$efm-orange: rgb(242, 146, 0);

.efm-bg {
  background-color: $efm-blue;
  color: white;
}

.btn-efm {
  @include button-variant($efm-blue, darken($efm-blue, 10%));
}


//$verdas-rot: rgb(142,25,237);
$verdas-red: #8E1917;
$verdas-gray: rgb(157, 163, 166);

.verdas-bg {
  background-color: $verdas-red;
  color: white;
}

.btn-verdas {
  @include button-variant($verdas-red, darken($verdas-red, 10%));
}

:root {
  --efm-blue: rgb(0, 65, 121);
  --efm-orange: rgb(242, 146, 0);
  --verdas-red: rgb(128, 0, 7);
  --verdas-gray: rgb(157, 163, 166);
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir-heavy.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: 'Avenir', sans-serif;
}

.btn{
  box-shadow: none!important;
border:none!important;
/*  border-radius: 7px!important;
  padding: 10px 20px!important; */
}

<template>
  <div v-if="ribbonEnabled" class="ribbon d-print-none">
    <a href="">{{ ribbonEnv }}</a>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from '@/vueAdapterUtils/utils';
import { computed } from 'vue';

const store = useStore();

const ribbonEnv = computed(() => store.getters.ribbonOnProfiles);
const ribbonEnabled = computed(
  () =>
    store.getters.ribbonOnProfiles &&
    store.getters.activeProfiles.indexOf(store.getters.ribbonOnProfiles) > -1
);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: rgba(170, 0, 0, 0.5);
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none;
  opacity: 0.75;
}

.ribbon a {
  color: #fff;
  display: block;
  font-weight: 400;
  margin: 1px 0;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
}
</style>

//import custom scss
@import 'custom';

$heading-size: 13px;
$heading-color: $efm-blue;
$heading-weight: bold;
$copy-size: 11px;
$footer-size: 8px;


/*
file to manage print styles
 */
@page {
  size: A4;
  margin: 0;
}

/**********************************
* a4 default styles
 */
.a4 {
  width: 209mm;
  height: 296mm;
  margin: 25px auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 10mm 10mm;
}
.a4  * {
  //font-family: EFMFont, sans-serif;
  font-family: Avenir, sans-serif;
}

.a4 .content {
  flex: 1;
}

.a4 p {
  font-size: $copy-size;
  margin: 0;
}

.a4 h2 {
  margin-top: 25px;
  font-size: $heading-size;
  font-weight: $heading-weight;
  color: $heading-color;
}

.a4 h3 {
  margin-top: 15px;
  font-size: $copy-size;
  font-weight: $heading-weight;
}

/**********************************
* footer styles
 */

.a4 .page-num{
  font-size: $footer-size;
}
footer.efm-footer {
  page-break-inside: avoid;

  p {
    font-size: $footer-size !important;
    margin: 0;
    line-height: 10px;
  }

  h2 {
    font-weight: bold;
    font-size: $copy-size !important;
    margin: 10px 0 0 0;
    color: $efm-blue;
  }
}

@media print {
  .no-print {
    display: none;
  }
  .a4 {
    margin: 0;
    box-shadow: none;
    page-break-after: always;
  }
  nav, footer {
    display: none !important;
  }
  .content-wrapper, .boxed-layout {
    max-width: unset;
    margin: 0;
    padding: 0;
  }

  /**
   reset design css
   **/
  .nav-bar-layout-wrapper, #app, body, html {
    max-width: unset !important;
    margin: 0 !important;
    padding: 0 !important;
    max-height: unset !important;
    min-height: unset !important;
    min-width: unset !important;
  }
  /**
  hide all direct svg children of body
  (needed to hide svg.js default svg)
   */
  body > svg {
    display: none;
  }
  .b-toaster {
    display: none;
  }
}

<template>
  <footer id="footer" class="footer d-print-none bg-dark text-white">
    <div class="boxed-layout pb-1">
      <div class="row">
        <div class="col-8">
          <p class="font-weight-bold">
            Check den Paul Risiko-Check <span class="version">{{ version }}</span>
          </p>
          <p>Berichterstellung zur Risikobewertung</p>
        </div>
        <div class="col text-right">
          <p class="font-weight-bold mb-2">Fehler & Support</p>
          <p>E: office@paukowitsch.com</p>
          <p>T: 0650 / 417 63 62</p>
        </div>
      </div>

      <div class="text-center mt-3">
        <small>
          A
          <a
            class="text-white mono font-weight-normal"
            href="https://paukowitsch.com"
            rel="external"
            target="_blank"
            >Paukowitsch Solutions Software </a
          >| ©
          {{ year }}
        </small>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { FE_VERSION } from '@/constants';

const version = 'v' + FE_VERSION;
const year = new Date().getFullYear();
</script>
<style scoped>
footer {
  margin-top: auto;
}

footer p {
  margin-bottom: 0;
}

.version {
  font-size: 10px;
}
</style>

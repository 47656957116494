<template>
  <b-navbar
    :class="mandantId == 1 ? 'efm-bg' : 'verdas-bg'"
    data-cy="navbar"
    toggleable="md"
    type="dark"
  >
    <b-navbar-brand b-link class="logo" to="/">
      <span class="navbar-title">Risikoprofil</span>
    </b-navbar-brand>
    <b-navbar-toggle
      aria-expanded="false"
      aria-label="Toggle navigation"
      class="navbar-toggler d-lg-none"
      data-toggle="collapse"
      href="javascript:void(0);"
      right
      target="header-tabs"
    >
      <font-awesome-icon icon="bars" />
    </b-navbar-toggle>

    <b-collapse id="header-tabs" is-nav>
      <b-navbar-nav class="ml-auto">
        <!-- home -->
        <b-nav-item v-if="authenticated" :to="{ name: 'Dashboard' }" exact>
          <span>
            <span>Home</span>
          </span>
        </b-nav-item>
        <!-- reports -->
        <b-nav-item-dropdown
          v-if="authenticated"
          id="entity-menu"
          active-class="active"
          class="pointer"
          data-cy="entity"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <span class="no-bold">Berichte</span>
          </span>
          <b-dropdown-item :to="{ name: 'Reports' }" exact> Alle</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'ReportCustomerSelection' }" exact> Neu</b-dropdown-item>
        </b-nav-item-dropdown>
        <!-- Einstellungen -->
        <b-nav-item-dropdown
          v-if="authenticated"
          id="entity-menu"
          active-class="active"
          class="pointer"
          data-cy="entity"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <span class="no-bold">Einstellungen</span>
          </span>
          <settings-menu></settings-menu>
        </b-nav-item-dropdown>

        <!-- App User Management -->
        <b-nav-item
          v-if="isAdmin()"
          :to="{ name: 'AppUsers' }"
          active-class="active"
          class="pointer"
          exact
        >
          <span>
            <span>User</span>
          </span>
        </b-nav-item>

        <!--   Admin dropdown management -->
        <b-nav-item-dropdown
          v-if="isAdmin()"
          id="admin-menu"
          :class="{ 'router-link-active': subIsActive('/admin') }"
          active-class="active"
          class="pointer"
          data-cy="adminMenu"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <span class="no-bold">Administration</span>
          </span>
          <b-dropdown-item
            v-if="isAdmin()"
            active-class="active"
            class="pointer"
            exact
            to="/admin/user-management"
          >
            <font-awesome-icon icon="user" />
            <span>Internal User</span>
          </b-dropdown-item>
          <b-dropdown-item active-class="active" to="/admin/metrics">
            <font-awesome-icon icon="tachometer-alt" />
            <span>Metrics</span>
          </b-dropdown-item>
          <b-dropdown-item active-class="active" to="/admin/health">
            <font-awesome-icon icon="heart" />
            <span>Health</span>
          </b-dropdown-item>
          <b-dropdown-item active-class="active" to="/admin/logs">
            <font-awesome-icon icon="tasks" />
            <span>Logs</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <!--   login logout dropdown -->
        <b-nav-item-dropdown
          v-if="authenticated"
          id="account-menu"
          :class="{ 'router-link-active': subIsActive('/account') }"
          active-class="active"
          class="pointer"
          data-cy="accountMenu"
          href="javascript:void(0);"
          right
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <span class="no-bold"> Account </span>
          </span>
          <b-dropdown-item :to="{ name: 'SetupPage' }" active-class="active">
            <font-awesome-icon icon="tasks" />
            <span>Setup</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="authenticated"
            id="logout"
            active-class="active"
            data-cy="logout"
            v-on:click="logout()"
          >
            <font-awesome-icon icon="sign-out-alt" />
            <span>Sign out</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts" setup>
import { FE_VERSION } from '@/constants';
import { computed, inject, ref } from 'vue';
import { useStore } from '@/vueAdapterUtils/utils';
import { useRoute, useRouter } from 'vue-router/composables';
import SettingsMenu from '@/pages/settings/settings-menu.vue';

const version = 'v' + FE_VERSION;
const store = useStore();
const router = useRouter();
const route = useRoute();

const accountService = inject('accountService');

const hasAnyAuthorityValues = ref({});

const mandantId = computed(() => {
  const acc = store.getters.account;
  if (acc) {
    return acc.mandantId;
  }
  return 0;
});

const authenticated = computed(() => store.getters.authenticated);
const inProduction = computed(() => store.getters.activeProfiles.indexOf('prod') > -1);

const subIsActive = input => {
  const paths = Array.isArray(input) ? input : [input];
  return paths.some(path => route.path.startsWith(path));
};

const logout = () => {
  localStorage.removeItem('cdp-gen-authenticationToken');
  sessionStorage.removeItem('cdp-gen-authenticationToken');
  store.commit('logout');
  if (route.path !== '/login') {
    return router.push({ name: 'Login' });
  }
  return Promise.resolve(router.currentRoute);
};

const hasAnyAuthority = authorities => {
  accountService()
    .hasAnyAuthorityAndCheckAuth(authorities)
    .then(value => {
      if (hasAnyAuthorityValues[authorities] !== value) {
        hasAnyAuthorityValues = { ...hasAnyAuthorityValues, [authorities]: value };
      }
    });
  return hasAnyAuthorityValues[authorities] ?? false;
};
const isAdmin = () => {
  return hasAnyAuthority('ROLE_ADMIN') && authenticated.value;
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ==========================================================================
    Navbar
    ========================================================================== */

@media screen and (min-width: 768px) {
  .navbar-toggler {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    /* display: none;
 */
  }
}

.navbar-title {
  display: inline-block;
  vertical-align: middle;
}

/* ==========================================================================
    Logo styles
    ========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px;
}

.logo .logo-img {
  height: 45px;
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}

.logo-img {
  height: 100%;
  background: url('../../../content/images/logo.png') no-repeat center center;
  background-size: contain;
  width: 100%;
  filter: drop-shadow(0 0 0.05rem white);
}
</style>
